<!--
 * @Author: your name
 * @Date: 2020-06-11 14:03:18
 * @LastEditTime: 2020-09-17 15:11:59
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \src\views\series\serieslist.vue
--> 
<template>
  <div>
    <!--banner-->
    <div class="banner">
      <img src="@/assets/images/seriesbanner.png" alt />
    </div>
    <!--系列列表-->
    <div class="main">
      <!--serierlist-->
      <div class="serierlsit" v-for="(item,index) in mylist" :key="item.id" id="list">
        <!--左-->
        <template v-if="index %2 == 0">
          <div class="left">
            <img :src="item.series.pic.picUrl" alt />
            <div class="opction">
              <p class="title">{{item.series.title}}</p>
              <p class="des">{{item.series.des}}</p>
              <button @click="xiliexq(item.series.id)">购买系列</button>
            </div>
          </div>
          <div class="right">
            <div class="right_con" v-for="(value,inx) in item.products" :key="value.id">
              <div class="images">
                <img :src="value.pic.picUrl" alt @click="tzlist(value.id)" />
              </div>
              <div class="text">
                <p>{{value.title}}</p>
                <p>{{value.desc}}</p>
                <p>{{value.minpriceStr}}</p>
                <button @click="addcar(value.id,$event)">添加至购物车</button>
                <img :src="value.iscare == 1 ? lovered : loveno" @click="collect(value,inx,index);" />
              </div>
            </div>
          </div>
        </template>

        <!--右-->
        <template v-if="index %2 !== 0">
          <div class="right">
            <div class="right_con" v-for="(value,inx) in item.products" :key="value.id">
              <div class="images">
                <img :src="value.pic.picUrl" alt @click="tzlist(value.id)" />
              </div>
              <div class="text">
                <p>{{value.title}}</p>
                <p>{{value.desc}}</p>
                <p>{{value.minpriceStr}}</p>
                <button @click="addcar(value.id,$event)">添加至购物车</button>
                <img :src="value.iscare == 1 ? lovered : loveno" @click="collect(value,inx,index);" />
              </div>
            </div>
          </div>
          <div class="left">
            <img :src="item.series.pic.picUrl" alt />
            <div class="opction le">
              <p class="title">{{item.series.title}}</p>
              <p class="des">{{item.series.des}}</p>
              <button @click="xiliexq(item.series.id)">购买系列</button>
            </div>
          </div>
        </template>
      </div>
      <p class="bomp">没有更多了</p>
    </div>
  </div>
</template>

<script>
import { series, add, collect } from "@/service/api.js";

export default {
  name: "serieslist",
  data() {
    return {
      page: 1,
      size: 3,
      mylist: [],
      loveno: require("@/assets/images/loveccc.png"),
      lovered: require("@/assets/images/lovered.png")
    };
  },
  watch: {
    "$store.state.height": function(item) {
      if (item < 520) {
        this.getData();
      }
    }
  },
  methods: {
    //跳转收藏
    tzlist(id) {
      this.$router.push({
        path: "/productInfo.html",
        query: {
          id: id
        }
      });
    },
    //系列
    xiliexq(i){
       this.$router.push({
              path: "/productList.html",
              query: {
                seriesid: i
              }
          });
    },
    //收藏
    collect(item, inx, index) {
      if (this.$store.getters["user/getToken"]) {
        let type = item.iscare == 1 ? 2 : 1;
        console.log(this.mylist);
        collect({
          type,
          productid: item.id
        }).then(() => {
          var s = item.iscare == 1 ? 0 : 1;
          this.mylist[index].products[inx].iscare = s;
        });
      } else {
        this.$notify.error({
          title: "未登录",
          message: "您还未登录，赶紧去登陆吧",
          offset: 100
        });
        let loading = this.$loading({
          lock: true,
          text: "您还未登录，正在跳转登陆页面...",
          spinner: "el-icon-loading",
          background: "rgba(0, 0, 0, 0.7)"
        });
        setTimeout(() => {
          loading.close();
          this.$router.push({
            path: "/createLogin.html?url=/serieslist"
          });
        }, 300);
      }
    },
    //初始化
    getData() {
      this.page++;
      this.getlist();
    },
    //初始化列表
    getlist() {
      let uid = this.$store.getters["user/getUid"];
      if (uid) {
        series({
          uid: uid,
          start: (this.page - 1) * this.size,
          size: this.size
        }).then(res => {
          this.mylist.push(...res.attachment.datas);
        });
      } else {
        series({
          start: (this.page - 1) * this.size,
          size: this.size
        }).then(res => {
          this.mylist.push(...res.attachment.datas);
        });
      }
    },
    //添加购物车
    addcar(e, event) {
      if (this.$store.getters["user/getToken"]) {
        let user = this.$store.getters["user/getuserdata"];
        add({
          uid: user.uid,
          pnum: 1,
          skuid: 0,
          sizeid:0,
          productid: e
        }).then(res => {
          console.log(res);
          if (res.status == 400) {
            this.$notify.error({
              title: "错误",
              message: res.message,
              offset: 100
            });
          } else if (res.status == 402) {
            this.$notify.error({
              title: "错误",
              message: res.message,
              offset: 100
            });
          } else if (res.status == 200) {
            //购物车动画

            var offset = $(".spw").offset();
            var img = $(event.target)
              .parent()
              .parent()
              .find(".images img")
              .attr("src");
            var flyer = $('<img class="u-flyer" src="' + img + 'style="z-index:99999;border-radius: 50%;width:50px;height:50px">');
            console.log(event, img, $(event.target));
            flyer.fly({
              start: {
                left: event.pageX, //开始位置（必填）#fly元素会被设置成position: fixed
                top: event.pageY //开始位置（必填）
              },
              end: {
                left: offset.left + 10, //结束位置（必填）
                top: offset.top + 10, //结束位置（必填）
                width: 0, //结束时宽度
                height: 0 //结束时高度
              },
              onEnd: function() {
                //结束回调
                // $("#msg")
                //   .show()
                //   .animate({ width: "250px" }, 200)
                //   .fadeOut(1000); //提示信息
                // addcar
                //   .css("cursor", "default")
                //   .removeClass("orange")
                //   .unbind("click");
              }
            });

            this.$store.commit("user/SETCAR", res.attachment.total);
            this.$notify({
              title: "成功",
              message: "您已成功添加此商品",
              type: "success",
              offset: 100
            });
          } else if (res.status == 204) {
            this.$notify.error({
              title: "错误",
              message: res.message,
              offset: 100
            });
            let loading = this.$loading({
              lock: true,
              text: res.message,
              spinner: "el-icon-loading",
              background: "rgba(0, 0, 0, 0.7)"
            });
            setTimeout(() => {
              loading.close();
              this.$router.push({
                path: "/productInfo.html",
                query: {
                  id: e
                }
              });
            }, 300);
          }
        });
      } else {
        this.$notify.error({
          title: "未登录",
          message: "您还未登录，赶紧去登陆吧",
          offset: 100
        });
        let loading = this.$loading({
          lock: true,
          text: "您还未登录，正在跳转登陆页面...",
          spinner: "el-icon-loading",
          background: "rgba(0, 0, 0, 0.7)"
        });
        setTimeout(() => {
          loading.close();
          this.$router.push({
            path: "/createLogin.html?url=/serieslist"
          });
        }, 300);
      }
    }
  },
  mounted() {
    this.getlist();
  }
};
</script>

<style lang="less" scoped>
.u-flyer {
  display: block;
  width: 50px;
  height: 50px;
  border-radius: 50px;
  position: fixed;
  z-index: 9999;
}
.banner {
  width: 100%;
  height: 400px;
  overflow: hidden;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
.main {
  width: 1404px;
  margin: 0 auto;
  margin-top: 70px;
}
.serierlsit {
  width: 100%;
  height: 620px;
  display: flex;
  justify-content: space-between;
  margin-top: 36px;
  animation: 0.3s opcy;
  .left {
    width: 50%;
    height: 100%;
    overflow: hidden;
    position: relative;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .right {
    width: 50%;
    height: 100%;
    overflow: hidden;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}
.opction {
  width: 258px;
  height: 100%;
  background: #000;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 1;
  display: none;
  background: rgba(0, 0, 0, 0.6);
  color: #fff;
  .title {
    width: 80%;
    margin: 0 auto;
    margin-top: 108px;
    font-size: 18px;
  }
  .des {
    width: 80%;
    margin: 0 auto;
    margin-top: 24px;
    font-size: 20px;
  }
  button {
    display: inline-block;
    width: 80%;
    height: 41px;
    outline: none;
    border: none;
    font-size: 16px;
    position: absolute;
    bottom: 140px;
    left: 50%;
    transform: translateX(-50%);
  }
}
.right_con {
  width: 100%;
  height: 305px;
  background: #f5f5f5;
  display: flex;
  justify-content: space-between;
  &:last-child {
    margin-top: 12px;
  }
  .images {
    width: 45%;
    height: 100%;
    overflow: hidden;
    cursor: pointer;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .text {
    width: 55%;
    text-align: center;
    position: relative;
    p:first-child {
      font-size: 22px;
      font-weight: 500;
      margin-top: 80px;
    }
    p:nth-child(2) {
      font-size: 14px;
      color: #999;
      margin: 0 auto;
      margin-top: 20px;
      width: 80%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    p:nth-child(3) {
      font-size: 22px;
      font-weight: 500;
      margin-top: 30px;
    }
    button {
      width: 280px;
      height: 41px;
      background: #921d22;
      outline: none;
      border: none;
      margin-top: 30px;
      color: #fff;
      font-size: 16px;
    }
    img {
      width: 20px;
      height: 20px;
      position: absolute;
      right: 15px;
      bottom: 35px;
    }
  }
}
.le {
  position: absolute;
  left: 0;
  top: 0;
}
.bomp {
  text-align: center;
  font-weight: 500;
  font-size: 42px;
  margin: 200px 0;
}
.left:hover {
  .opction {
    display: block;
    animation: 0.3s opcs;
  }
}

.left:hover {
  .le {
    display: block;
    animation: 0.3s opc;
  }
}

@keyframes opc {
  0% {
    transform: translateX(-100%);
  }
  25% {
    transform: translateX(-75%);
  }
  50% {
    transform: translateX(-50%);
  }
  75% {
    transform: translateX(-25%);
  }
  100% {
    transform: translateX(0);
  }
}

@keyframes opcs {
  0% {
    transform: translateX(100%);
  }
  25% {
    transform: translateX(75%);
  }
  50% {
    transform: translateX(50%);
  }
  75% {
    transform: translateX(25%);
  }
  100% {
    transform: translateX(0);
  }
}

@keyframes opcy {
  0% {
    transform: translateY(100%);
  }
  25% {
    transform: translateY(75%);
  }
  50% {
    transform: translateY(50%);
  }
  75% {
    transform: translateY(25%);
  }
  100% {
    transform: translateY(0);
  }
}
</style>